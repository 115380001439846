#breast {
  animation: shake 1s both infinite;
}

@keyframes shake {
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }

  90% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  80% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  60% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  40% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  30% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  20% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  10% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

#right_nipplec3,
#left_nipplec3 {
  animation: shake_nipples 0.5s both infinite;
}

@keyframes shake_nipples {
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }

  90% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  80% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  60% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  40% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  30% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  20% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  10% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

#speech3 {
  opacity: 0;
  animation: shake_bubble 1s linear infinite;
  transition-delay: 2.5s
}


@keyframes shake_bubble {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
    opacity: 1;
  }

  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
    opacity: 1;
  }

  20% {
    transform: translate(1px, 2px) rotate(0deg);
    opacity: 1;
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
    opacity: 1;
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
    opacity: 1;
  }

  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
    opacity: 1;
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
    opacity: 1;
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
    opacity: 1;
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
    opacity: 1;
  }

  90% {
    transform: translate(-3px, 0px) rotate(1deg);
    opacity: 1;
  }

  100% {
    transform: translate(1px, 1px) rotate(0deg);
    opacity: 1;
  }
}