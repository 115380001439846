.thinker__wrapper {
  display: grid;
  grid-template-rows: 0.1fr 1fr 1fr;
  height: 100vh;
  padding: 0 2vh;
  position: relative;
}

.thinker__button button {
  border: none;
  background-color: transparent;
  color: #f2f2f2;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  letter-spacing: 0.3rem;
  position: relative;
  width: 3.5rem;
}

.blob {
  position: absolute;
  width: 3.8rem;
  left: 0;
  top: 0;
}

.blob::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/blobS.png');
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}

.blob:hover::before {
  animation: blob 5s infinite;
  opacity: 1;
}

@keyframes blob {
  0% {
    transform: rotate(5deg)
  }

  5% {
    transform: rotate(10deg);
  }

  15% {
    opacity: 0.9;
    transform: rotate(0deg);
  }

  25% {
    opacity: 1;
    transform: rotate(-5deg);
  }

  35% {
    opacity: 1;
    transform: rotate(5deg);
  }

  45% {
    opacity: 1;
    transform: rotate(15deg);
  }

  55% {
    opacity: 1;
    transform: rotate(0deg);
  }

  70% {
    opacity: 0.9;
    transform: rotate(-5deg);
  }
}

.landing__p {
  color: #f2f2f2;
}