.landing__content {
  height: 90vh;
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.landing__p {
  font-size: calc(20px + 2vmin);
}

.landing__p-span {
  font-size: calc(20px + 1vmin);
}

.landing__content-two {
  height: 50vh;
  color: #f2f2f2;
}

.landing__contribute {
  color: #f2f2f2;
  margin: 10vh 0;
}