.hero__image {
  max-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  overflow: hidden;
}

.hero__gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  position: relative;
  height: 15rem;
  overflow: hidden;
}

.hero__gallery img {
  height: 80vh;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: auto;
  margin: auto;
  /* transform-origin: 50% 0; */
  transform: scale(0.8);
  transition: transform 1s ease-out;
}

.hero__gallery:hover img {
  transform: scale(1.2);
}


.hero__title {
  text-align: start;
  position: absolute;
  top: 75%;
  left: 35%;
  transform: translate(-50%, -80%);
  color: white;
  font-size: 9.9vw;
}

@media (min-width: 40rem) {
  .hero__image {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .hero__gallery {
    height: 35rem;
  }
}