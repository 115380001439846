.noPage__wrapper {
  color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.speech-bubble {
  position: relative;
  background: #f2f2f2;
  border-radius: 0.4em;
  margin-bottom: 4vh;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #f2f2f2;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.speech__content {
  text-transform: uppercase;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  padding: 1rem;
}

.hannah__wrapper {
  /* max-width: 50rem; */
}

.hannah__404 {
  clip-path: polygon(
    0% 5%,
    100% 5%,
    100% 25%,
    0% 24%,
    0% 31%,
    100% 37%,
    100% 60%,
    0% 67%,
    0% 75%,
    100% 80%,
    100% 95%,
    0% 95%
  );
}
